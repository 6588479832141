<template>
  <div class="columns is-mobile is-multiline is-variable is-0-mobile is-3-tablet is-4-desktop is-4-widescreen is-4-fullhd">
    <div class="column is-full-mobile is-one-quarter-desktop is-one-third-tablet is-one-quarter-widescreen is-one-quarter-fullhd">
      <img style="height:100%" src="../assets/img/biologiste-edit.svg"/>
    </div>
    <div class="column is-full-mobile is-one-quarter-desktop is-one-third-tablet is-one-quarter-widescreen is-one-quarter-fullhd"
      v-for="(analysis,index) in analyses"
      :key="index"
    >
      <analysis-card
        buttonLabel="Edit"
        :analysis="analysis"
        @click="selectAnalysis(analysis)"
      />
    </div>
  </div>
</template>

<script>
import allAnalyses from '../../data/analysis.json'
export default {
  name: 'Edit',
  components: {
    'analysis-card': () => import('@/components/AnalysisCard.vue')
  },
  data () {
    return {
      selectedAnalysis: null,
      analyses: allAnalyses['edit']
    }
  },
  methods: {
    selectAnalysis: function (analysis) {
      this.$router.push(analysis.link)
    }
  }
}
</script>
